var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-4"},[_c('a',{staticClass:"tool-card-ref",attrs:{"href":"#portcheck"}},[_c('div',{staticClass:"tool-card",on:{"click":function($event){return _vm.show('portcheck')}}},[_c('h5',[_vm._v("Port Checker")]),_c('p',[_vm._v("Überprüfe die offenen Ports auf deinem Gerät.")])])])]),_c('div',{staticClass:"col-md-4"},[_c('a',{staticClass:"tool-card-ref",attrs:{"href":"#mousemover"}},[_c('div',{staticClass:"tool-card",on:{"click":function($event){return _vm.show('mousemover')}}},[_c('h5',[_vm._v("Mouse Mover")]),_c('p',[_vm._v("Simuliere Mausbewegungen, sowie Klicks, um den Bildschirm aktiv zu halten.")])])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('a',{staticClass:"tool-card-ref",attrs:{"href":"https://ipcheck.vegabyte.de","target":"_blank"}},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("IP Checker")]),_c('p',[_vm._v("Überprüfe welche IP's und Ports bei einer Website genutzt werden. Auch extern eingebundene Dateien werden dabei berücksichtigt, um eine Optimale Freigabe in der Firewall zu ermöglichen.")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("Clipper")]),_c('p',[_vm._v("Ein Addin für Browser, mit dem man Texte vorschreiben kann und immer wieder einfügt über einen Rechtsklick.")]),_c('p',[_vm._v("Aktuell befindet sich das Tool in der Entwicklung")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("MP4 Converter")]),_c('p',[_vm._v("Konvertiere Videos in das MP4-Format.")]),_c('p',[_vm._v("Aktuell befindet sich das Tool in der Entwicklung")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("Schulungs Tool")]),_c('p',[_vm._v("Füge Mitarbeiter hinzu, baue Fragebögen, sende Ergebnisse, etc.")]),_c('p',[_vm._v("Aktuell befindet sich das Tool in der Entwicklung")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("#SOON")]),_c('p',[_vm._v("Weitere Tools werden folgen.")]),_c('p',[_vm._v("Wir bitten hier um etwas geduld.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("#SOON")]),_c('p',[_vm._v("Weitere Tools werden folgen.")]),_c('p',[_vm._v("Wir bitten hier um etwas geduld.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tool-card"},[_c('h5',[_vm._v("#SOON")]),_c('p',[_vm._v("Weitere Tools werden folgen.")]),_c('p',[_vm._v("Wir bitten hier um etwas geduld.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"ad-container"},[_c('p',[_vm._v("Werbung")])]),_c('div',{staticClass:"ad-container"},[_c('p',[_vm._v("Werbung")])])])
}]

export { render, staticRenderFns }